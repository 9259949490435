import {
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonLabel,
	IonButton,
	IonSearchbar
} from '@ionic/react';
import './ListGuide.css';
import React from 'react';
import { requestWebService, LIST_ALLIEVI_INSTRUCTOR, SEARCH_ALLIEVI_INSTRUCTOR } from '../utils/webservice';
import SpinnerLoading from '../components/SpinnerLoading';

const ListAllieviInstructor: React.FC = () => {
	const [list, setList] = React.useState<Array<any>>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [searchValue, setSearchValue] = React.useState("");
	const [autoscuola, setAutoscuola] = React.useState<number | null>(null);
	const [user, setUser] = React.useState<number | null>(null);

	React.useEffect(() => {
		getInfoStudentCalendario(); // Carica l'elenco iniziale
	}, []);

	const getInfoStudentCalendario = async () => {
		setLoading(true);
		requestWebService(LIST_ALLIEVI_INSTRUCTOR, {}).then((res) => {
			console.log('Dati ricevuti da LIST_ALLIEVI_INSTRUCTOR:', res); // Aggiungi questo log per visualizzare la risposta completa
			if (res && res.status === 200) {
				setList(res.list || []);
				setAutoscuola(res.autoscuola); // Memorizza l'ID dell'autoscuola
				setUser(res.user); // Memorizza l'ID dell'istruttore
			} else {
				alert("Errore nel caricamento degli allievi.");
			}
			setLoading(false);
		});
	};

	const searchAllievi = async (value?: string) => {
		const query = value !== undefined ? value : searchValue;
		if (!query.trim()) {
			getInfoStudentCalendario();
			return;
		}
		setLoading(true);

		// Passa term, id_autoscuola e instructor nella richiesta
		requestWebService(SEARCH_ALLIEVI_INSTRUCTOR, {
			term: query,
			id_autoscuola: autoscuola, // Usa il valore autoscuola dallo stato
			instructor: user // Usa il valore dell'istruttore dallo stato
		}).then((res) => {
			if (res && res.status === 200) {
				setList(res.list || []);
			} else {
				alert("Errore durante la ricerca.");
			}
			setLoading(false);
		});
	};
	

	const handleInput = (e: any) => {
		setSearchValue(e.target.value || "");
	};

	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/bacheca" />
					</IonButtons>
					<IonTitle>Allievi</IonTitle>
				</IonToolbar>
				<IonToolbar>
					<IonSearchbar
						placeholder='Cerca allievo...'
						value={searchValue}
						showClearButton='always'
						onKeyUp={(e) => {
							if (e.key === 'Enter' || e.keyCode === 13) {
								searchAllievi();
							}
						}}
						onIonClear={() => { 
							setSearchValue(""); 
							getInfoStudentCalendario(); // Ricarica la lista completa quando il campo di ricerca viene svuotato
						}}
						onIonChange={handleInput}></IonSearchbar>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{loading && list.length === 0 && <SpinnerLoading />}
				{!loading && list && list.map((item, index) => {
					return (
						<IonItem key={index}>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{item.nome + " " + item.cognome}</h3>
								</IonText>
								{item.email && <p>Email : {item.email}</p>}
								{item.cellulare && <p>Cellulare : {item.cellulare}</p>}
								{item.telefono && <p>Telefono : {item.telefono}</p>}
								<IonButton ion-button color='warning' style={{ float: "right" }} routerLink={"/add-guida/" + item.iduser}>Aggiungi guida</IonButton>
							</IonLabel>
						</IonItem>
					);
				})}
			</IonContent>
		</IonPage>
	);
};

export default ListAllieviInstructor;
